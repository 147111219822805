<template>
  <div style="height: 100%; width: 100%">
    <b-modal :title="isNew? $t('task.group.title_new_task') : $t('task.group.title_edit_task')"
        v-model="tShow"
        @ok="ok"
        @hidden="closeModal"
        content-class="shadow"
        no-close-on-backdrop
        >
      <div class="container pl-0 pr-0">
        <b-row>
          <b-col cols="10" style="width: 87%; flex: 0 0 87%; max-width: 87%; padding-right: 0;">
            <b-form-group :label="$t('task.field.name')">
              <b-input-group>
                <b-form-input ref="newTaskName" v-model="tName" maxlength="25" @keyup="newTasknamekeyup" :class="{ 'is-invalid': isTaskNameDuplicated }"></b-form-input>
              </b-input-group>
              <b-form-invalid-feedback class="alert-danger form-field-alert" style="margin-top: 1px" :class="{ 'd-block': isTaskNameDuplicated }">
                <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ $t('task.error.task_name_already_exists') }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col v-if="isColorVisible" cols="1" style="width: 13%; flex: 0 0 13%; max-width: 13%;">
            <div>
              <Color v-model="tColor" :update="updatedColor" styleClass="" labelStyleClass="color-label" :disabled="isColorReadOnly"/>
            </div>
          </b-col>
          <b-col cols="12" v-if="isSkillVisible">
            <b-form-group>
              <label class="mr-1">{{ $t(`task.field.skills`) }}</label>
              <button v-if="!isSkillReadOnly" id="SKILL_ADD" class="btn-action" @click="skillSelectorToggle"><font-awesome-icon :icon="['far', 'plus']"/>
                <b-popover
                  target="SKILL_ADD"
                  placement="top"
                  triggers="hover"
                  :content="$t('task.button.skill_add')">
                </b-popover>
              </button>
              <BadgeGroup v-model="tSkills" :readOnly="isSkillReadOnly">
                <template v-slot:default="{ item, index }">
                  <Badge @badgeRemove="skillBadgeRemove(index)" @badgeClick="skillBadgeClick(item.uuId)"
                    :text="item.level ? `${item.name} (${item.level})` : item.name" 
                    variant="primary" 
                    :pillable="!!item.pillable" :key="index" 
                    :readOnly="isSkillReadOnly"
                  />
                </template>
              </BadgeGroup>
            </b-form-group>
          </b-col>
          <b-col cols="12" v-if="isStaffVisible">
            <b-form-group>
              <label class="mr-1">{{ $t(`task.field.staffs`) }}</label>
              <button v-if="!isStaffReadOnly" id="STAFF_ADD" class="btn-action" @click="staffSelectorToggle"><font-awesome-icon :icon="['far', 'plus']"/>
                <b-popover
                  target="STAFF_ADD"
                  placement="top"
                  triggers="hover"
                  :content="$t('task.button.staff_add')">
                </b-popover>
              </button>
            
              <BadgeGroup v-model="tStaff" :readOnly="isStaffReadOnly">
                <template v-slot:default="{ item, index }">
                  <Badge @badgeRemove="staffBadgeRemove(index)" @badgeClick="staffBadgeClick(item.uuId)"
                    :text="`${item.name} (${Math.trunc(item.utilization * 100)}%)`" 
                    :variant="item.genericStaff? 'info' : 'primary'"
                    :pillable="!!item.pillable" :key="index" 
                    :readOnly="isStaffReadOnly"
                  />
                </template>
              </BadgeGroup>
            </b-form-group>
          </b-col>
          <b-col cols="12" v-if="isResourceVisible">
            <b-form-group>
              <label class="mr-1">{{ $t(`task.field.resources`) }}</label>
              <button v-if="!isResourceReadOnly" id="RESOURCE_ADD" class="btn-action" @click="resourceSelectorToggle"><font-awesome-icon :icon="['far', 'plus']"/>
                <b-popover
                  target="RESOURCE_ADD"
                  placement="top"
                  triggers="hover"
                  :content="$t('task.button.resource_add')">
                </b-popover>
              </button>
              <BadgeGroup v-model="tResources" :readOnly="isResourceReadOnly">
                <template v-slot:default="{ item, index }">
                  <Badge @badgeRemove="resourceBadgeRemove(index)" @badgeClick="resourceBadgeClick(item.uuId)"
                    :text="`${item.name} (${item.quantity})`" 
                    variant="primary" 
                    :pillable="!!item.pillable" :key="index"
                    :readOnly="isResourceReadOnly"
                  />
                </template>
              </BadgeGroup>
            </b-form-group>
          </b-col>
          <b-col cols="12" v-if="isRebateVisible">
            <b-form-group>
              <label class="mr-1">{{ $t(`task.field.rebates`) }}</label>
              <button v-if="!isRebateReadOnly" id="REBATE_ADD" class="btn-action" @click="rebateSelectorToggle"><font-awesome-icon :icon="['far', 'plus']"/>
                <b-popover
                  target="REBATE_ADD"
                  placement="top"
                  triggers="hover"
                  :content="$t('task.button.rebate_add')">
                </b-popover>
              </button>

              <b-form-text class="rebate-total mr-1">
                {{ $t(`task.field.total_rebate`, [totalRebate]) }}
              </b-form-text>

              <BadgeGroup v-model="tRebates" :readOnly="isRebateReadOnly">
                <template v-slot:default="{ item, index }">
                  <Badge @badgeRemove="rebateBadgeRemove(index)" @badgeClick="rebateBadgeClick(item.uuId)"
                    :text="`${item.name} (${formatRebate(item.rebate)}%)`" 
                    variant="primary" 
                    :pillable="!!item.pillable" :key="index"
                    :readOnly="isRebateReadOnly"
                  />
                </template>
              </BadgeGroup>
            </b-form-group>
          </b-col>

          <b-col cols="12" v-if="isTagVisible">
            <b-form-group>
              <TagList :readOnly="isTagReadOnly" :tags="tTags" @modified="tagsModified" />
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button :disabled="tName === null || tName.trim().length == 0 || isTaskNameDuplicated" size="sm" variant="success" @click="ok()">{{ $t('button.confirm') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>
    
    <SkillSelectorModal v-if="state.skillSelectorShow" :show.sync="state.skillSelectorShow" 
      @ok="skillSelectorOk"
    />
    <SkillLevelModal v-if="state.skillLevelEditShow" :show.sync="state.skillLevelEditShow" 
      :uuId="skillLevelEdit.uuId" 
      :name="skillLevelEdit.name" 
      :level="skillLevelEdit.level" 
      @ok="skillLevelOk"
    />
    <template v-for="(item, index) in toConfirmSkills" >
      <SkillLevelModal :show.sync="item.show" :key="`skill-${index}`"
        :canApplyAll="toConfirmSkills.length > 1" 
        :uuId="item.uuId" 
        :name="item.name" 
        :level="item.level" 
        @ok="toConfirmSkillOk" 
        @cancel="toConfirmSkillCancel"
      />
    </template>

    <StaffSelectorModal v-if="state.staffSelectorShow" 
      :hideBookings="false" 
      :show.sync="state.staffSelectorShow" 
      @ok="staffSelectorOk" 
    />
    <StaffUtilizationModal v-if="state.staffUtilizationEditShow" :show.sync="state.staffUtilizationEditShow" 
      :uuId="staffUtilizationEdit.uuId" 
      :name="staffUtilizationEdit.name" 
      :utilization="staffUtilizationEdit.utilization" 
      @ok="staffUtilizationOk"
    />
    <template v-for="(item, index) in toConfirmStaffs">
      <StaffUtilizationModal v-if="item.show" :key="`staff-${index}`" 
        :show.sync="item.show" 
        :canApplyAll="toConfirmStaffs.length > 1" 
        :uuId="item.uuId" 
        :name="item.name" 
        :utilization="item.utilization" 
        @ok="toConfirmStaffOk" 
        @cancel="toConfirmStaffCancel"
      />
    </template>

    <b-modal :title="$t('project.confirmation.title_apply_rebates')"
        v-model="promptApplyRebates"
        :ok-title="$t('button.yes')"
        :cancel-title="$t('button.no')"
        @ok="applyRebates"
        content-class="shadow"
        no-close-on-backdrop
        >
      <div class="d-block">
        <p>{{ $t('task.confirmation.apply_rebates') }}</p>
      </div>
      <template v-slot:modal-footer="{ cancel }">
        <b-button size="sm" variant="success" @click="applyRebates">{{ $t('button.yes') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.no') }}</b-button>
      </template>
    </b-modal>

    <ResourceSelectorModal v-if="state.resourceSelectorShow" 
      :show.sync="state.resourceSelectorShow" 
      @ok="resourceSelectorOk"
    />
    <ResourceUnitModal v-if="state.resourceUnitEditShow" :show.sync="state.resourceUnitEditShow" 
      :uuId="resourceUnitEdit.uuId" 
      :name="resourceUnitEdit.name" 
      :unit="resourceUnitEdit.unit" 
      :utilization="parseFloat(resourceUnitEdit.utilization)" 
      @ok="resourceUnitOk"
    />
    <template v-for="(item, index) in toConfirmResources">
      <ResourceUnitModal v-if="item.show" 
        :key="`resource-${index}`" 
        :show.sync="item.show" 
        :canApplyAll="toConfirmResources.length > 1" 
        :uuId="item.uuId" 
        :name="item.name" 
        :unit="item.unit" 
        :utilization="parseFloat(item.utilization)" 
        @ok="toConfirmResourceOk" 
        @cancel="toConfirmResourceCancel"/>
    </template>

    <RebateSelectorModal v-if="state.rebateSelectorShow" :show.sync="state.rebateSelectorShow" 
      @ok="rebateSelectorOk"
    />
  </div>
</template>

<script>
import { rebateService, resourceService, skillService, staffService, tagService } from '@/services';
import { toFixed } from '@/helpers/task-duration-process';
export default {
  name: "TaskGroupTaskEdit",
  components : {
    BadgeGroup: () => import('@/components/BadgeGroup/BadgeGroup'),
    Badge: () => import('@/components/BadgeGroup/components/Badge'),
    Color: () => import('@/components/Color/Color.vue'),
    SkillSelectorModal: () => import('@/components/modal/SkillSelectorModal'),
    SkillLevelModal: () => import('@/components/modal/SkillLevelModal'),
    StaffSelectorModal: () => import('@/components/modal/StaffSelectorModal'),
    StaffUtilizationModal: () => import('@/components/modal/StaffUtilizationModal'),
    ResourceSelectorModal: () => import('@/components/modal/ResourceSelectorModal'),
    ResourceUnitModal: () => import('@/components/modal/ResourceUnitModal'),
    RebateSelectorModal: () => import('@/components/modal/RebateSelectorModal'),
    TagList: () => import('@/components/Tag/TagList.vue'),
  },
  props: {
    show: {
      type: Boolean, required: true
    },
    name: {
      type: String, default: null
    },
    color: {
      type: String, default: null
    },
    skills: {
      type: Array, default: () => []
    },
    staff: {
      type: Array, default: () => []
    },
    resources: {
      type: Array, default: () => []
    },
    rebates: {
      type: Array, default: () => []
    },
    tags: {
      type: Array, default: () => []
    },
    existingNames: {
      type: Array, default: () => []
    },
    isNew: {
      type: Boolean, default: false
    }
  },
  data() {
    return {
      tShow: false,
      tName: null,
      tColor: null,
      tSkills: [],
      tStaff: [],
      tResources: [],
      tRebates: [],
      tTags: [],
      
      oName: null,
      oColor: null,
      oSkills: [],
      oStaff: [],
      oResources: [],
      oRebates: [],
      oTags: [],

      updatedColor: null,
      taskNameEditIndex: null,
      isTaskNameDuplicated: false,

      state: {
        skillSelectorShow:        false,
        skillLevelEditShow:       false,
        staffSelectorShow:        false,
        staffUtilizationEditShow: false,
        resourceSelectorShow:     false,
        resourceUnitEditShow:     false,
        rebateSelectorShow:       false,
      },

      toConfirmSkills: [],
      skillLevelEdit: {
        uuId:  null,
        name:  null,
        level: null,
      },

      toConfirmStaffs: [],
      staffUtilizationEdit: {
        uuId:  null,
        name:  null,
        utilization: null,
      },
      promptApplyRebates: false,

      toConfirmResources: [],
      resourceUnitEdit: {
        uuId: null,
        name: null,
        unit: null,
        utilization: null
      },

      rebateEdit: {
        uuId: null,
        name: null,
        rebate: null
      }
    }
  },
  created() {
    this.permissionName = 'TASK';
  },
  beforeMount() {
    this.initModal();
  },
  mounted() {
    this.tShow = this.show;
    if (this.tShow) {
      setTimeout(() => {
        if (this.$refs.newTaskName != null) {
          this.$refs.newTaskName.focus();
        }
      }, 300)
    }
  },
  beforeDestroy() {
    if (this.rebatesToApply != null) {
      this.rebatesToApply = null;
    }
    this.permissionName = null;
  },
  computed: {
    totalRebate() {
      var total = 0;
      for (const rebate of this.tRebates) {
        total += rebate.rebate;
      }
      return toFixed(total*100, 2);
    },
    isColorVisible() {
      return this.canView(this.permissionName, ['color']) 
      && this.canAdd(this.permissionName, ['color'])
      && this.canEdit(this.permissionName, ['color']);
    },
    isColorReadOnly() {
      return !this.canEdit(this.permissionName, ['color']);
    },
    isSkillVisible() {
      //Link creation requires main entity's edit permission
      return this.canView('SKILL') && this.canView(this.permissionName, ['SKILL']) 
      && this.canEdit(this.permissionName, ['SKILL']);
    },
    isSkillReadOnly() {
      return !this.canEdit(this.permissionName, ['SKILL']);
    },
    isStaffVisible() {
      //Link creation requires main entity's edit permission
      return this.canView('STAFF') && this.canView(this.permissionName, ['STAFF']) 
      && this.canEdit(this.permissionName, ['STAFF']);
    },
    isStaffReadOnly() {
      return !this.canEdit(this.permissionName, ['STAFF']);
    },
    isResourceVisible() {
      //Link creation requires main entity's edit permission
      return this.canView('RESOURCE') && this.canView(this.permissionName, ['RESOURCE']) 
      && this.canEdit(this.permissionName, ['RESOURCE']);
    },
    isResourceReadOnly() {
      return !this.canEdit(this.permissionName, ['RESOURCE']);
    },
    isRebateVisible() {
      //Link creation requires main entity's edit permission
      return this.canView('REBATE') && this.canView(this.permissionName, ['REBATE']) 
      && this.canEdit(this.permissionName, ['REBATE']);
    },
    isRebateReadOnly() {
      return !this.canEdit(this.permissionName, ['REBATE']);
    },
    isTagVisible() {
      return this.canView('TAG') && this.canView(this.permissionName, ['TAG']);
    },
    isTagReadOnly() {
      return !this.canAdd('TAG') || !this.canEdit('TAG') || !this.canEdit(this.permissionName, ['TAG']);
    },
  },
  watch: {
    show(nValue) {
      this.tShow = nValue;
      if (this.tShow) {
        this.initModal();
        setTimeout(() => {
          if (this.$refs.newTaskName != null) {
            this.$refs.newTaskName.focus();
          }
        }, 300)
      }
    }
  },
  methods: {
    initModal() {
      this.tName = this.oName = this.name;
      this.tColor = this.oColor = this.color;
      this.tSkills = this.skills != null ? JSON.parse(JSON.stringify(this.skills)) : [];
      this.oSkills = this.skills != null ? JSON.parse(JSON.stringify(this.skills)) : [];
      this.tStaff = this.staff != null ? JSON.parse(JSON.stringify(this.staff)) : [];
      this.oStaff = this.staff != null ? JSON.parse(JSON.stringify(this.staff)) : [];
      this.tResources = this.resources != null ? JSON.parse(JSON.stringify(this.resources)) : [];
      this.oResources = this.resources != null ? JSON.parse(JSON.stringify(this.resources)) : [];
      this.tRebates = this.rebates != null ? JSON.parse(JSON.stringify(this.rebates)) : [];
      this.oRebates = this.rebates != null ? JSON.parse(JSON.stringify(this.rebates)) : [];
      this.tTags = this.tags != null ? JSON.parse(JSON.stringify(this.tags)) : [];
      this.oTags = this.tags != null ? JSON.parse(JSON.stringify(this.tags)) : [];
      this.toConfirmSkills = [];
      
      this.toConfirmStaffs = [];
      this.staffUtilizationEdit.uuId = null;
      this.staffUtilizationEdit.name = null;
      this.staffUtilizationEdit.utilization = null;
      this.rebatesToApply = null;

      //Retrieve skill details based on uuIds
      //, filter out invalid skill, refresh skill name in case it changed since last save.
      const skillList = this.tSkills.filter(i => i.uuId != null).map(i => ({ uuId: i.uuId }));
      if (skillList.length > 0) {
        skillService.query(skillList)
        .then(resp => {
          if (resp.data?.jobCase != null && resp.data[resp.data.jobCase] != null) {
            const list = [];
            const data = resp.data[resp.data.jobCase];
            for (const d of data) {
              const found = this.tSkills.find(i => i.uuId == d.uuId);
              if (found) {
                found.name = d.name;
                list.push(found);
              }
            }
            this.tSkills.splice(0, this.tSkills.length, ...list);
          }
        });
      }

      //Retrieve staff details based on uuIds
      //, filter out invalid staff, refresh staff name in case it changed since last save.
      const staffList = this.tStaff.filter(i => i.uuId != null).map(i => ({ uuId: i.uuId }));
      if (staffList.length > 0) {
        staffService.query(staffList)
        .then(resp => {
          if (resp.data?.jobCase != null && resp.data[resp.data.jobCase] != null) {
            const list = [];
            const data = resp.data[resp.data.jobCase];
            for (const d of data) {
              const found = this.tStaff.find(i => i.uuId == d.uuId);
              if (found) {
                found.name = d.name;
                list.push(found);
              }
            }
            this.tStaff.splice(0, this.tStaff.length, ...list);
          }
        });
      }

      //Retrieve resource details based on uuIds
      //, filter out invalid resource, refresh resource name in case it changed since last save.
      const resourceList = this.tResources.filter(i => i.uuId != null).map(i => ({ uuId: i.uuId }));
      if (resourceList.length > 0) {
        resourceService.query(resourceList)
        .then(resp => {
          if (resp.data?.jobCase != null && resp.data[resp.data.jobCase] != null) {
            const list = [];
            const data = resp.data[resp.data.jobCase];
            for (const d of data) {
              const found = this.tResources.find(i => i.uuId == d.uuId);
              if (found) {
                found.name = d.name;
                list.push(found);
              }
            }
            this.tResources.splice(0, this.tResources.length, ...list);
          }
        });
      }

      //Retrieve rebate details based on uuIds
      //, filter out invalid rebate, refresh rebate name in case it changed since last save.
      const rebateList = this.tRebates.filter(i => i.uuId != null).map(i => ({ uuId: i.uuId }));
      if (rebateList.length > 0) {
        rebateService.query(rebateList)
        .then(resp => {
          if (resp.data?.jobCase != null && resp.data[resp.data.jobCase] != null) {
            const list = [];
            const data = resp.data[resp.data.jobCase];
            for (const d of data) {
              const found = this.tRebates.find(i => i.uuId == d.uuId);
              if (found) {
                found.name = d.name;
                list.push(found);
              }
            }
            this.tRebates.splice(0, this.tRebates.length, ...list);
          }
        });
      }

      //Retrieve tag details based on uuIds
      //, filter out invalid tag, refresh tag name in case it changed since last save.
      const tagList = this.tTags.filter(i => i.uuId != null).map(i => ({ uuId: i.uuId }));
      if (tagList.length > 0) {
        tagService.query(tagList)
        .then(resp => {
          if (resp.data?.jobCase != null && resp.data[resp.data.jobCase] != null) {
            const list = [];
            const data = resp.data[resp.data.jobCase];
            for (const d of data) {
              const found = this.tTags.find(i => i.uuId == d.uuId);
              if (found) {
                found.name = d.name;
                list.push(found);
              }
            }
            this.tTags.splice(0, this.tTags.length, ...list);
          }
        });
      }
    },
    async ok() {
      // we need to create the tags if any have been typed in
      for (const tag of this.tTags) {
        if (!tag.uuId) {
          tag.uuId = await tagService.create([{name: tag.name}]).then((response) => {
            if (response.data[response.data.jobCase].length !== 0) {
              return response.data[response.data.jobCase][0].uuId;
            }
          });
        }
      }
      
      this.$emit('ok', { 
        name: this.tName.trim()
        , color: this.tColor 
        , skills: this.tSkills.map(i => ({ uuId: i.uuId, name: i.name, level: i.level }))
        , staff: this.tStaff.map(i => ({ uuId: i.uuId, name: i.name, utilization: i.utilization }))
        , resources: this.tResources.map(i => ({ uuId: i.uuId, name: i.name, quantity: parseInt(i.quantity), utilization: i.utilization }))
        , rebates: this.tRebates.map(i => ({ uuId: i.uuId, name: i.name, rebate: i.rebate }))
        , tags: this.tTags.map(i => ({ uuId: i.uuId, name: i.name }))
      });
      this.closeModal();
    },
    newTasknamekeyup(event) {
      const newName = this.tName;
      if (this.existingNames.includes(newName)) {
        this.isTaskNameDuplicated = true;
      } else {
        this.isTaskNameDuplicated = false;
      }
      if (this.isTaskNameDuplicated != true && event.which === 13 && this.tName != null && this.tName.trim().length > 0) {
        this.ok();
      }
    },
    closeModal() {
      this.$emit('update:show', false);
    },
    skillBadgeRemove: function(index) {
      this.tSkills.splice(index,1)
    },
    skillBadgeClick(id) {
      const selected = this.tSkills.find(i => i.uuId === id);
      const edit = this.skillLevelEdit;
      edit.uuId = selected.uuId;
      edit.name = selected.name;
      edit.level = selected.level;
      this.state.skillLevelEditShow = true;
    },
    skillSelectorToggle() {
      this.state.skillSelectorShow = true;
    },
    skillSelectorOk({ details }) {
      const newSkills = details.map(i => { return { uuId: i.uuId, name: i.name, level: null, color: i.color, show: false }});
      this.toConfirmSkills.splice(0, this.toConfirmSkills.length, ...newSkills);
      for(let i = this.toConfirmSkills.length-1; i > -1; i--) {
        this.toConfirmSkills[i].toBeShown = true;
      }
      this.toConfirmSkills[this.toConfirmSkills.length - 1].toBeShown = false;
      this.toConfirmSkills[this.toConfirmSkills.length - 1].show = true;
    },
    skillLevelOk({ id, name, level, oldId }) {
      const oldIdIndex = oldId? this.tSkills.findIndex(i => i.uuId === oldId) : -1;
      if(oldIdIndex != -1) {
        this.tSkills.splice(oldIdIndex, 1);
      }
      const idIndex = this.tSkills.findIndex(i => i.uuId === id);
      const selected = this.tSkills[idIndex];
      if (selected != null) {
        selected.uuId = id;
        selected.name = name;
        selected.level = level;
      }
    },
    toConfirmSkillOk({ id, level, applyToAll }) {
      const selected = this.toConfirmSkills[this.toConfirmSkills.findIndex(i => i.uuId === id)]
      selected.level = level;
      
      if (selected.color && this.tColor == null) {
        this.tColor = selected.color;
      }
      
      const toBeShown = this.toConfirmSkills.filter(i => i.toBeShown);
      if(toBeShown.length === 0) {
        this.toConfirmSkillCommit();
      }
      else if (applyToAll) {
        for (const entry of toBeShown) {
          entry.toBeShown = false;
          entry.level = level;
        }
        this.toConfirmSkillCommit();
      }
      else {
        toBeShown[toBeShown.length - 1].toBeShown = false;
        toBeShown[toBeShown.length - 1].show = true;
      }
    },
    toConfirmSkillCancel() {
      this.toConfirmSkillCommit();
    },
    toConfirmSkillCommit() {
      //Set all show state to false. To close/hide remaining modals if any.
      for(let i = 0, len = this.toConfirmSkills.length; i < len; i ++) {
        this.toConfirmSkills[i].show = false;
      }
      const confirmedSkills = this.toConfirmSkills.filter(i => i.level && i.level.length > 0);
      const ids = confirmedSkills.map(i => i.uuId);
      const currentIds = this.tSkills.map(i => i.uuId);
      const duplicatedIds = currentIds.filter(i => ids.includes(i));
      const newSkills = confirmedSkills.filter(i => !currentIds.includes(i.uuId));

      let skill = null;
      for(let i = 0, len = this.tSkills.length; i < len; i++) {
        skill = this.tSkills[i];
        if(duplicatedIds.includes(skill.uuId)) {
          skill.level = confirmedSkills.find(i => i.uuId === skill.uuId).level;
        }
      }
      
      newSkills.forEach(i => {
        if (this.tName === null) {
          this.$refs.typeahead.inputValue = i.name;
          this.tName = i.name;
        }
        
        this.tSkills.push( {uuId: i.uuId, name: i.name, level: i.level} );
      });
    },
    
    staffBadgeRemove: function(index) {
      this.tStaff.splice(index,1)
    },
    staffBadgeClick(id) {
      const selected = this.tStaff.find(i => i.uuId === id);
      const edit = this.staffUtilizationEdit;
      edit.uuId = selected.uuId;
      edit.name = selected.name;
      edit.utilization = selected.utilization;
      this.state.staffUtilizationEditShow = true;
    },
    staffSelectorToggle() {
      this.state.staffSelectorShow = true;
    },
    async staffSelectorOk({ details }) {
      const newStaffs = details.map(i => { 
        return { 
          uuId: i.uuId
          , name: i.name
          , utilization: null
          , show: false
          , duration: i.duration
          , genericStaff: i.genericStaff 
        }
      });
      this.toConfirmStaffs.splice(0, this.toConfirmStaffs.length, ...newStaffs);
      for(let i = this.toConfirmStaffs.length-1; i > -1; i--) {
        this.toConfirmStaffs[i].toBeShown = true;
      }
      this.toConfirmStaffs[this.toConfirmStaffs.length - 1].toBeShown = false;
      this.toConfirmStaffs[this.toConfirmStaffs.length - 1].show = true;
      
      if (typeof details[0].rebates === 'undefined') {
        details[0].rebates = await staffService.list({ holder: details[0].uuId })
        .then(response => {
          return response.data.length > 0 ? response.data[0].rebates : [];
        });
      }
      
      if (details[0].rebates &&
          details[0].rebates.length > 0) {
        this.rebatesToApply = [];
        for (const det of details) {
          for (const r of det.rebates) {
            if (this.tRebates.findIndex(reb => reb.uuId === r.uuId) === -1) {
              this.rebatesToApply.push(r);
            } 
          }
        }
      }
    },
    staffUtilizationOk({ id, name, utilization, oldId, genericStaff }) {
      if(oldId != id) {
        const oldIdIndex = oldId? this.tStaff.findIndex(i => i.uuId === oldId) : -1;
        if(oldIdIndex != -1) {
          this.tStaff.splice(oldIdIndex, 1);
        }
      }
      const newIdIndex = this.tStaff.findIndex(i => i.uuId === id);
      if(newIdIndex > -1) {
        const selected = this.tStaff[newIdIndex];
        selected.uuId = id;
        selected.name = name;
        selected.utilization = utilization;
      } else {
        this.tStaff.push({
          uuId: id,
          name: name,
          utilization: utilization,
          genericStaff: !!genericStaff
        });
      }
    },
    toConfirmStaffOk({ id, utilization, applyToAll, genericStaff }) {
      const selected = this.toConfirmStaffs[this.toConfirmStaffs.findIndex(i => i.uuId === id)]
      selected.utilization = utilization;
      if(genericStaff != undefined) {
        selected.genericStaff = genericStaff;
      }
      const toBeShown = this.toConfirmStaffs.filter(i => i.toBeShown);
      if(toBeShown.length === 0) {
        this.toConfirmStaffCommit();
      }
      else if (applyToAll) {
        for (const entry of toBeShown) {
          entry.toBeShown = false;
          entry.utilization = utilization;
        }
        this.toConfirmStaffCommit();
      }
      else {
        toBeShown[toBeShown.length - 1].toBeShown = false;
        toBeShown[toBeShown.length - 1].show = true;
      }
    },
    toConfirmStaffCancel() {
      this.toConfirmStaffCommit();
    },
    toConfirmStaffCommit() {
      //Set all show state to false. To close/hide remaining modals if any.
      for(let i = 0, len = this.toConfirmStaffs.length; i < len; i ++) {
        this.toConfirmStaffs[i].show = false;
      }
      const confirmedStaffs = this.toConfirmStaffs.filter(i => i.utilization != null);
      const ids = confirmedStaffs.map(i => i.uuId);
      const currentIds = this.tStaff.map(i => i.uuId);
      const duplicatedIds = currentIds.filter(i => ids.includes(i));
      const newStaffs = confirmedStaffs.filter(i => !currentIds.includes(i.uuId));
      
      let staff = null;
      for(let i = 0, len = this.tStaff.length; i < len; i++) {
        staff = this.tStaff[i];
        if(duplicatedIds.includes(staff.uuId)) {
          staff.utilization = confirmedStaffs.find(i => i.uuId === staff.uuId).utilization;
        }
      }

      newStaffs.forEach(i => {
        this.tStaff.push( {uuId: i.uuId, name: i.name, utilization: i.utilization, genericStaff: !!i.genericStaff} );
      });
      
      
      
      if (this.rebatesToApply &&
          this.rebatesToApply.length > 0) {
        this.promptApplyRebates = true;  
      }
    },
    applyRebates() {
      this.tRebates.push(...this.rebatesToApply);
      this.rebatesToApply = null;
      this.promptApplyRebates = false;
    },
    resourceBadgeRemove: function(index) {
      this.tResources.splice(index,1)
    },
    resourceBadgeClick(id) {
      const selected = this.tResources.find(i => i.uuId === id);
      const edit = this.resourceUnitEdit;
      edit.uuId = selected.uuId;
      edit.name = selected.name;
      edit.unit = parseInt(selected.quantity);
      edit.utilization = selected.utilization;
      this.state.resourceUnitEditShow = true;
    },
    resourceSelectorToggle() {
      this.state.resourceSelectorShow = true;
    },
    resourceSelectorOk({ details }) {
      const newResources = details.map(i => { return { uuId: i.uuId, name: i.name, unit: null, show: false }});
      this.toConfirmResources.splice(0, this.toConfirmResources.length, ...newResources);
      for(let i = this.toConfirmResources.length-1; i > -1; i--) {
        this.toConfirmResources[i].toBeShown = true;
      }
      this.toConfirmResources[this.toConfirmResources.length - 1].toBeShown = false;
      this.toConfirmResources[this.toConfirmResources.length - 1].show = true;
    },
    resourceUnitOk({ id, name, unit, utilization, oldId }) {
      const oldIdIndex = oldId? this.tResources.findIndex(i => i.uuId === oldId) : -1;
      if(oldIdIndex != -1) {
        this.tResources.splice(oldIdIndex, 1);
      }
      const idIndex = this.tResources.findIndex(i => i.uuId === id);
      if (idIndex !== -1) {
        const selected = this.tResources[idIndex];
        selected.uuId = id;
        selected.name = name;
        selected.quantity = unit;
        selected.utilization = utilization;
      }
      else {
        this.tResources.push( {uuId: id, name: name, quantity: unit, utilization: utilization} );
      }
    },
    toConfirmResourceOk({ id, unit, utilization, applyToAll }) {
      const selected = this.toConfirmResources[this.toConfirmResources.findIndex(i => i.uuId === id)]
      selected.unit = unit;
      selected.utilization = utilization;
      const toBeShown = this.toConfirmResources.filter(i => i.toBeShown);
      if(toBeShown.length === 0) {
        this.toConfirmResourceCommit();
      }
      else if (applyToAll) {
        for (const entry of toBeShown) {
          entry.toBeShown = false;
          entry.unit = unit;
          entry.utilization = utilization;
        }
        this.toConfirmResourceCommit();
      }
      else {
        toBeShown[toBeShown.length - 1].toBeShown = false;
        toBeShown[toBeShown.length - 1].show = true;
      }
    },
    toConfirmResourceCancel() {
      this.toConfirmResourceCommit();
    },
    toConfirmResourceCommit() {
      //Set all show state to false. To close/hide remaining modals if any.
      for(let i = 0, len = this.toConfirmResources.length; i < len; i ++) {
        this.toConfirmResources[i].show = false;
      }
      const confirmedResources = this.toConfirmResources.filter(i => i.unit && i.unit > 0);
      const ids = confirmedResources.map(i => i.uuId);
      const currentIds = this.tResources.map(i => i.uuId);
      const duplicatedIds = currentIds.filter(i => ids.includes(i));
      const newResources = confirmedResources.filter(i => !currentIds.includes(i.uuId));

      let resource = null;
      for(let i = 0, len = this.tResources.length; i < len; i++) {
        resource = this.tResources[i];
        if(duplicatedIds.includes(resource.uuId)) {
          resource.quantity = confirmedResources.find(i => i.uuId === resource.uuId).unit;
          resource.utilization = confirmedResources.find(i => i.uuId === resource.uuId).utilization;
        }
      }

      newResources.forEach(i => {
        this.tResources.push( {uuId: i.uuId, name: i.name, quantity: i.unit, utilization: i.utilization} );
      });
    },
    rebateSelectorToggle() {
      this.state.rebateSelectorShow = true;
    },
    rebateSelectorOk({details}) {
      const rebates = this.tRebates;
      const currentIds = rebates.map(i => i.uuId);
      if (this.rebateEdit.uuId) {
        rebates.splice(rebates.findIndex(j => j.uuId === this.rebateEdit.uuId), 1);
      }
      
      const remainingrebates = details.filter(i => !Object.prototype.hasOwnProperty.call(i, 'oldId'));

      remainingrebates.forEach(i => {
        const id = i.uuId;
        if(currentIds.includes(id)) {
          const index = rebates.findIndex(j => j.uuId === id);
          rebates[index].uuId = i.uuId;
          rebates[index].name = i.name;
          rebates[index].rebate = i.rebate;
        } else {
          rebates.push({ uuId: i.uuId, name: i.name, rebate: i.rebate });
        }
      })
      this.rebateEdit = { uuId: null };
    },
    rebateBadgeRemove: function(index) {
      this.tRebates.splice(index,1)
    },
    rebateBadgeClick() {
      this.state.rebateSelectorShow = true;
    },
    tagsModified({tags}) {
      this.tTags = tags;
    },
    formatRebate(rebate) {
      return toFixed(rebate*100, 2);
    }
  }
}
</script>

<style lang="scss" scoped>
.color-label {
  margin-bottom: 7px;
}

.rebate-total {
  text-align: right;
  float: right;
  display: inline-block;
}
</style>